html {
    font-size: 8px;
    height: 100vh;
}
body {
    font-family: Gotham, Montserrat, "Century Gothic", Futura, sans-serif;
    font-size: 2rem;
    height: 100%;
}
html, body {
    margin: 0;
}
[id="__next"] {
    height: 100%;
    width: 100%;
}
