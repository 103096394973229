@font-face {
    font-family: 'GothamLight';
    src: url(https://static.navegate.com/fonts/GothamLight.eot);
    src: local('GothamLight'), url(https://static.navegate.com/fonts/GothamLight.eot?#iefix) format('embedded-opentype'), url(https://static.navegate.com/fonts/GothamLight.woff) format('woff'), url(https://static.navegate.com/fonts/GothamLight.ttf) format('truetype'), url(https://static.navegate.com/fonts/GothamLight.otf) format('opentype');
}

@font-face {
    font-family: 'GothamBook';
    src: url(https://static.navegate.com/fonts/GothamBook.eot);
    src: local('GothamBook'), url(https://static.navegate.com/fonts/GothamBook.eot?#iefix) format('embedded-opentype'), url(https://static.navegate.com/fonts/GothamBook.woff) format('woff'), url(https://static.navegate.com/fonts/GothamBook.ttf) format('truetype'), url(https://static.navegate.com/fonts/GothamBook.otf) format('opentype');
}

@font-face {
    font-family: 'GothamMedium';
    src: url(https://static.navegate.com/fonts/GothamMedium.eot);
    src: local('GothamMedium'), url(https://static.navegate.com/fonts/GothamMedium.eot?#iefix) format('embedded-opentype'), url(https://static.navegate.com/fonts/GothamMedium.woff) format('woff'), url(https://static.navegate.com/fonts/GothamMedium.ttf) format('truetype'), url(https://static.navegate.com/fonts/GothamMedium.otf) format('opentype');
}

@font-face {
    font-family: 'GothamBold';
    src: url(https://static.navegate.com/fonts/GothamBold.eot);
    src: local('GothamBold'), url(https://static.navegate.com/fonts/GothamBold.eot?#iefix) format('embedded-opentype'), url(https://static.navegate.com/fonts/GothamBold.woff) format('woff'), url(https://static.navegate.com/fonts/GothamBold.ttf) format('truetype'), url(https://static.navegate.com/fonts/GothamBold.otf) format('opentype');
}
