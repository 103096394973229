@font-face {
    font-family: 'Gotham';
    font-weight: 100;
    src: local('GothamLight'),
    url(https://static.navegate.com/fonts/GothamLight.woff) format('woff'),
    url(https://static.navegate.com/fonts/GothamLight.ttf) format('truetype'),
    url(https://static.navegate.com/fonts/GothamLight.otf) format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-weight: normal;
    src: url(https://static.navegate.com/fonts/GothamBook.eot);
    src: local('GothamBook'),
    url(https://static.navegate.com/fonts/GothamBook.eot?#iefix) format('embedded-opentype'),
    url(https://static.navegate.com/fonts/GothamBook.woff) format('woff'),
    url(https://static.navegate.com/fonts/GothamBook.ttf) format('truetype'),
    url(https://static.navegate.com/fonts/GothamBook.otf) format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-weight: bold;
    src: url(https://static.navegate.com/fonts/GothamMedium.eot);
    src: local('GothamMedium'),
    url(https://static.navegate.com/fonts/GothamMedium.eot?#iefix) format('embedded-opentype'),
    url(https://static.navegate.com/fonts/GothamMedium.woff) format('woff'),
    url(https://static.navegate.com/fonts/GothamMedium.ttf) format('truetype'),
    url(https://static.navegate.com/fonts/GothamMedium.otf) format('opentype');
}
